<template>
  <section class="border-b border-t border-[#a18463]/20 p-8 lg:p-16">
    <h2
      class="text-xs text-green-700 font-bold mb-4"
      data-aos="fade-up"
      data-aos-once="true"
    >
      NEWSLETTER
    </h2>
    <h3
      class="text-xl mb-6 max-w-[250px] mx-auto leading-[25px]"
      data-aos="fade-up"
      data-aos-once="true"
    >
      Subscribe to recieve latest news and information
    </h3>
    <div
      class="bg-white flex max-w-[310px] mx-auto p-2 shadow-ex"
      data-aos="fade-up"
      data-aos-once="true"
    >
      <input
        class="flex-1 px-3 py-1 outline-none"
        placeholder="Enter your email"
      />
      <button
        type="button"
        class="text-xs px-2 py-1 text-white bg-green-600 hover:opacity-80 active:scale-95"
      >
        Submit
      </button>
    </div>
  </section>
</template>
